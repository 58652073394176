.generic-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.generic-checkbox .generic-checkbox-box {
  width: 20px;
  height: 20px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  border: 1px solid #000000;
  background: #ffffff;
  min-height: 20px;
  max-height: 20px;
}

.generic-checkbox .generic-checkbox-box.checked {
  background-color: white;
}

.generic-checkbox-checkmark {
  /*font-size: 13px;*/
  color: black;
  height: 20px;
}
