.items-count-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    box-sizing: border-box;
    background-color: #f9f9f9;
    flex-basis: 25%;
    overflow-y: scroll;
    overflow-x: scroll;
    height: 100%;
    justify-content: start;
    align-items: center;
    justify-items: start;
    cursor: pointer;

    @media only screen and (min-width: 1200px) {
        flex-basis: 18%;
    }


    &.full {
        flex-basis: 100%;
        margin: 0;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 400px));
        justify-content: center;
        padding: 16px;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .clear-filter-container {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        padding-right: 5vw;

        .clear-filter {
            background-color: #007bff;
            padding: 8px;
            border-radius: 4px;
            color: white;
            font-weight: 700;
        }
    }

    .category {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 8px;
        border: 1px solid #f88d8d;
        border-radius: 4px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        width: 90%;

        .title {
            display: flex;
            font-size: 10px;
            font-weight: 700;
            align-items: flex-end;
            justify-content: flex-end;
            text-decoration: underline;
            max-height: 15px;
            width: calc(100% - 16px);
        }

        .items-count-item {
            gap: 1vw;
            display: flex; /* Use display: contents to ensure each item spans two columns */

            &.active {
                background-color: #f1c40f !important;
            }

            .items-count-item-key,
            .items-count-item-value {
                padding: 8px;
                border-bottom: 1px solid #f0f0f04a;
                text-align: left;
                font-weight: 600;
            }

            .items-count-item-key {
                font-weight: bold;
                background-color: #f0f0f04a;
                width: 100%;

                &.active {
                    background-color: #f1c40f !important;
                    border-bottom: none;
                }
            }

            .items-count-item-value {
                text-align: right;
            }

            .items-count-item-key:last-child,
            .items-count-item-value:last-child {
                border-bottom: none; /* Remove bottom border from the last row */
            }
        }
    }
}





