.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
  font-size: 14px;
}

.login-form {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  border-radius: 8px;
  width: 30vw;
  height: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.login-title {
  margin-bottom: 1rem;
  text-align: center;
  color: #333;
}

.login-inputGroup {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.login-label {
  margin-bottom: 0.5rem;
  color: #555;
}

.login-input {
  padding: 0.75rem;
  border-radius: 4px;
  width: 28vw;
  border: 1px solid #ccc;
  font-size: 14px;
}

.login-button {
  padding: 0.75rem;
  border-radius: 4px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(0, 0, 255, 0.3);
  border-top: 5px solid #5252cd;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.login-button .spinner {
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-top: 3px solid white;
  width: 20px;
  height: 20px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
