.order-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    margin-bottom: 20px;

    &.open {
        background-color: #b0afff;
    }

    .title-container {

        .title {
            font-size: 20px;
            font-weight: 600;
            color: #333;
        }
    }

    .items {
        display: table;
        flex-direction: column;
        margin-top: 10px;

        .item {
            display: table-row;
            justify-content: space-between;
            margin-top: 10px;

            &.active {
                background: #f1c40f;
            }

            .cell {
                display: table-cell;
                padding: 8px;
                border: 1px solid #ddd;
                text-align: left;
                vertical-align: middle;
            }
        }
    }
}
