.home-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100vw;
  height: 100vh;
  gap: 1vh;
}

.home-main-container .top-bar {
  height: 5vh;
  min-height: 5vh;
  background: #007bff;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
}

.home-main-container .top-bar .icon-holder {
  position: absolute;
  right: 3vw;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  gap: 2vw;
}

.home-main-container .top-bar .icon-holder .icon {
  margin-right: 8px;
  height: 3vh;
  width: 3vh;
}

.home-main-container .top-bar .icon-holder .icon img {
  height: 100%;
  width: 100%;
}

.home-main-container .top-bar .icon-holder .icon.spin {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.home-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100vw;
  height: 93vh;
}

.home-container .orders-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  justify-content: center;
  width: 75%;
  height: 100%;
  padding: 16px;
  box-sizing: border-box;
  overflow-y: scroll;

  @media only screen and (min-width: 1200px) {
    flex-basis: 82%;
  }
}

@media (min-width: 600px) {
  .home-container .orders-container {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

@media (min-width: 900px) {
  .home-container .orders-container {
    grid-template-columns: repeat(3, 1fr);

    @media only screen and (min-width: 1500px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
